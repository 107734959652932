// AUTH
import ForgetPassword from '@auths/ForgetPassword';
import ResetPassword from '@auths/ResetPassword';
import Login from '@auths/Login';

// GUEST
import Maintenance from '@pages/Maintenance';

// USER
import Home from '@pages/Home';
import Genealogy from '@pages/Genealogy';
import Swap from '@pages/Swap';
import CompleteProfile from '@pages/CompleteProfile';
import Deposit from '@pages/Wallet/Deposit';
import PurchasePlan from '@pages/Package/PurchasePlan';
import Withdrawal from '@pages/Wallet/Withdrawal';
import PlanHistories from '@pages/Package/PlanHistory';
import Transfer from '@pages/Wallet/Transfer';
import Pnl from '@pages/BotTrading';
import Game from '@pages/Game';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    // BOT TRADING
    {
        path: "/trade-history",
        component: <Pnl />,
        exact: true,
        showLogo: true,
    }
    // {
    //     path: "/stake",
    //     component: <Stake/>,
    //     showTutorBtn: false,
    //     showBackBtn : false,
    //     showLogo: true,
    // },
    // {
    //     path: "/staking",
    //     component: <Staking/>,
    //     showTutorBtn: false,
    //     showBackBtn : true,
    //     showLogo: true,
    //     noHeader: true,
    // },
    // SETTING  
    // {
    //     path: "/setting/profile",
    //     component: <Profile />,
    //     title: 'title.profile',
    //     showLogo: true,
    //     showBackBtn: true,
    //     backTitle: 'title.profile',
    //     // showExtendMargin: true,
    // },
    // {
    //     path: "/setting",
    //     component: <Setting />,
    //     title: 'title.setting',
    //     showLogo: true,
    // },
    // {
    //     path: "/setting/password",
    //     component: <ProfilePassword />,
    //     title: 'title.password',
    //     showLogo: true,
    //     showBackBtn: true,
    //     showTutorBtn: false,
    //     backTitle: 'title.password',
    //     // showExtendMargin: true,
    // },
    // {
    //     path: "/setting/security-password",
    //     component: <ProfileSecurityPassword />,
    //     title: 'title.securityPassword',
    //     showLogo: true,
    //     showBackBtn: true,
    //     showTutorBtn: false,
    //     backTitle: 'title.securityPassword',
    //     // showExtendMargin: true,
    // },
    // {
    //     path: "/setting/crypto-address",
    //     component: <CryptoAddress />,
    //     title: 'title.cryptoAddress',
    //     showLogo: true,
    //     showBackBtn: true,
    //     showTutorBtn: false,
    //     backTitle: 'title.cryptoAddress',
    //     // showExtendMargin: true,
    // },
    // WALLET
    // {
    //     path: "/wallets/:page",
    //     component: <Wallet/>,
    //     exact: true,
    //     showLogo: true,
    // },
    // {
    //     path: "/wallet-listing",
    //     component: <WalletListing/>,
    //     exact: true,
    //     showTutorBtn: false,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/fundIn",
    //     showLogo: true,
    //     // showExtendMargin: true,
    //     backTitle: 'title.wallet',
    // },
    // {
    //     path: "/commission-listing",
    //     component: <CommissionListing/>,
    //     exact: true,
    //     showTutorBtn: false,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/fundIn",
    //     showLogo: true,
    //     // showExtendMargin: true,
    //     backTitle: 'title.wallet',
    // },
    // {
    //     path: "/wallet/deposit",
    //     component: <Deposit/>,
    //     exact: true,
    //     showTutorBtn: true,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/fundIn",
    //     showLogo: true,
    //     // showExtendMargin: true,
    //     backTitle: 'wallet.deposit',
    // },
    // {
    //     path: "/:type/transactionHistory/:id",
    //     component: <TransactionHistory/>,
    //     exact: true,
    //     showTutorBtn: false,
    //     showBackBtn : true,
    //     showLogo: true,
    //     // showExtendMargin: true,
    //     backTitle: 'title.transactionHistory',
    // },
    // {
    //     path: "/wallet/game-topup/:id",
    //     component: <GameTopUp/>,
    //     exact: true,
    //     showLogo: true,
    //     showTutorBtn: true,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/gameTopUp/1",
    //     // showExtendMargin: true,
    //     backTitle: 'wallet.gameTopup',
    // },
    // {
    //     path: "/wallet/withdrawal/:id",
    //     component: <Withdrawal/>,
    //     exact: true,
    //     showLogo: true,
    //     showTutorBtn: true,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/cashOut/1",
    //     showExtendMargin: true,
    //     // backTitle: 'wallet.withdraw',
    //     noHeader: true,
    // },
    // {
    //     path: "/wallet/transfer/:id",
    //     component: <Transfer/>,
    //     exact: true,
    //     showLogo: true,
    //     showTutorBtn: true,
    //     showBackBtn : true,
    //     tutorUrl : "/tutorial/transfer/1",
    //     noHeader: true,
    //     // showExtendMargin: true,
    //     // backTitle: 'wallet.transfer'
    // },
    // {
    //     path: "/wallet/convert/:id",
    //     component: <Convert/>,
    //     exact: true,
    //     showLogo: true,
    //     showTutorBtn: true,
    //     showBackBtn : true,
    //     // tutorUrl : "/tutorial/convert/1",
    //     noHeader: true,
    //     // showExtendMargin: true,
    //     // backTitle: 'wallet.transfer'
    // },
    // {
    //     path: "/packages/purchase",
    //     component: <PurchasePlan/>,
    //     exact: true,
    //     showLogo: true,
    //     showTutorBtn: false,
    //     showBackBtn : true,
    //     backTitle: 'title.package'
    // },
    // {
    //     path: "/contact-us",
    //     component: <ContactUs />,
    //     title: 'title.contactUs',
    //     showBackBtn : true,
    //     showLogo: false,
    //     noHeader: true,
    //     backTitle: 'title.contactUs'
    // }
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/",
        // component: <Home />,
        component: <PurchasePlan />,
        showTutorBtn: false,
        showBackBtn: false,
        showLogo: true,
    },
    {
        path: "/packages/purchase",
        component: <PurchasePlan/>,
        exact: true,
        showLogo: true,
        showTutorBtn: false,
        showBackBtn : false,
    },
    // {
    //     path: "/email-verification",
    //     component: <EmailVerification />,
    //     title: 'title.emailVerification',
    // },
    {
        path: "/maintenance",
        component: <Maintenance />,
        title: 'maintenance.title',
        showLogo: true,
        noHeader: true,
    },
    // GENEALOGY
    {
        path: "/community",
        component: <Genealogy />,
        exact: true,
        showLogo: true,
    },
    {
        path: "/swap/:wallet",
        component: <Swap />,
        exact: true,
        showLogo: true,
    },
    // {
    //     path: "/wallet",
    //     component: <Deposit />,
    //     exact: true,
    //     showLogo: true,
    // },
    {
        path: "/wallet/withdrawal/:id",
        component: <Withdrawal/>,
        exact: true,
        showLogo: true,
        // showTutorBtn: true,
        showBackBtn : false,
        // tutorUrl : "/tutorial/cashOut/1",
        // showExtendMargin: true,
        // backTitle: 'wallet.withdraw',
        // noHeader: true,
    },
    {
        path: "/my-hub",
        component: <PlanHistories/>,
        exact: true,
        showLogo: true,
    },
    {
        path: "/wallet/transfer/:id",
        component: <Transfer/>,
        exact: true,
        showLogo: true,
        showBackBtn : false,
    },
    // GAME
    {
        path: "/games",
        component: <Game />,
        exact: true,
        showLogo: true,
        showBackBtn : false,
    },
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    // {
    //     path: "/register",
    //     component: <Register />,
    //     title: 'login.signUp',
    //     showBackBtn: true,
    //     noHeader: true,
    // },
     {
         path: "/login",
         component: <Login />,
         title: 'title.login',
         showBackBtn: true,
         noHeader: true,
     },
    {
        path: "/forgot-password",
        component: <ForgetPassword />,
        title: 'forgotPassword.title',
        showBackBtn: true,
        noHeader: true,
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        title: 'resetPassword.title',
        showBackBtn: true,
        noHeader: true,
    },
    // {
    //     path: "/landing",
    //     component: <SplashScreen />,
    //     title:'title.splashScreen',
    //     noHeader: true,
    // },
];

export const first_login_routes = [
    {
        path: "/complete-profile",
        component: <CompleteProfile />,
        title: 'title.completeProfile',
        showBackBtn: true,
        noHeader: false,
    },
];

import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Grid, Link, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import moment from 'moment';
import { truncate } from '@utils/Tools';

import { FiExternalLink } from "react-icons/fi";
import { GoCopy } from "react-icons/go";

const PnL = () => {
    const { addAlert, setLoading } = useNotificationLoading();
    const { t } = useTranslation();
    const theme = useTheme();

    const styles = useStyles();
    const isMountedRef = useRef(null);

    const [page, setPage] = useState(1);
    const [currPage, setCurrPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [listing, setListing] = useState([]);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;

        getUrl(`/bot-tradings`, { per_page: 10, page: page }).then((response) => {
            if (response.status && isMountedRef.current) {
                let { data, current_page, last_page } = response.data.listing;
                setTotal(last_page);
                setCurrPage(current_page);

                if (page < last_page) { window.addEventListener('scroll', handleScroll); }
                if (page > 1) { setListing(_.concat(listing, data)); }
                else { setListing(data); }
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })

        return () => { isMountedRef.current = false; window.removeEventListener('scroll', handleScroll); }
    }, [page, searchParams]);

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
        if (bottom) {
            setPage(page + 1);
        }
    }

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }
    }, []);

    return (
        <div className='p-t-90 p-lr-20' style={{ minHeight: '100vh' }}>
            <div className='txt-center flex-col p-b-30' style={{ gap: 8 }}>
                <p style={{ fontSize: 36, fontWeight: 600 }}>{t('title.tradeHistory')}</p>
                <p className='p-t-10' style={{ fontSize: 14, fontWeight: 400, color: theme.palette.gray.main }}>{t('botTrading.subtitle')}</p>
            </div>
            <div className='bor20 w-full p-all-20 m-b-30' style={{ background: '#ffffff', gap: 16 }}>
                <p className='fs-16' style={{ fontWeight: 600 }}>{t('botTrading.transactionRecord')}</p>
                {
                    listing?.length > 0 ? (
                        listing.map((_row, key) => {
                            let time = moment(_row.first_hash_timestamp).local().format('YYYY-MM-DD HH:mm:ss');
                            return (
                                <div key={_row.id}>
                                    <div className="divider-dark w-full m-t-20"></div>
                                    <div className='p-t-20'>
                                        <div className='flex-sb-m fs-12'>
                                            <p style={{ color: theme.palette.gray.main }}>{t('botTrading.time')}</p>
                                            <p style={{ color: theme.palette.textColor }}>{time}</p>
                                        </div>
                                        <div className='flex-sb-m fs-12 p-t-10'>
                                            <p style={{ color: theme.palette.gray.main }}>{t('botTrading.margin')}</p>
                                            <p style={{ color: theme.palette.textColor }}>{_row.margin}%</p>
                                        </div>
                                        <div className='flex-sb-m fs-12 p-t-10'>
                                            <p style={{ color: theme.palette.gray.main }}>PnL</p>
                                            <p style={{ color: '#1CC871' }}>{_row.pnl}</p>
                                        </div>
                                        <div className='flex-sb-m fs-12 p-t-10'>
                                            <p style={{ color: theme.palette.gray.main }}>{t('botTrading.hash1')}</p>
                                            <p className='flex-m' style={{ color: theme.palette.textColor }}>
                                                {truncate(_row.tx_hash_1, 13)}
                                                <Link href={`https://bscscan.com/tx/${_row.tx_hash_1}`} color="primary" underline="hover" target="_blank" rel="nofollow">
                                                    <FiExternalLink className='fs-14 m-lr-5 pointer' style={{ color: theme.palette.primary.main }} />
                                                </Link>
                                                <GoCopy className='fs-14 pointer' style={{ color: theme.palette.gray.main }} onClick={() => copyShareLink(_row.tx_hash_1)} />
                                            </p>
                                        </div>
                                        <div className='flex-sb-m fs-12 p-t-10'>
                                            <p style={{ color: theme.palette.gray.main }}>{t('botTrading.hash2')}</p>
                                            <p className='flex-m' style={{ color: theme.palette.textColor }}>
                                                {truncate(_row.tx_hash_2, 13)}
                                                <Link href={`https://bscscan.com/tx/${_row.tx_hash_2}`} color="primary" underline="hover" target="_blank" rel="nofollow">
                                                    <FiExternalLink className='fs-14 m-lr-5 pointer' style={{ color: theme.palette.primary.main }} />
                                                </Link>
                                                <GoCopy className='fs-14 pointer' style={{ color: theme.palette.gray.main }} onClick={() => copyShareLink(_row.tx_hash_2)} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <p className='fs-12 p-t-20 txt-title txt-center'>
                            {t('table.noRecord')}
                        </p>
                    )
                }
                {
                    listing?.length > 0 && (
                        <p className='fs-12 p-t-20 txt-title txt-center'>
                            {currPage === total ? t('table.endOfTheList') : t('table.loadingList')}
                        </p>
                    )
                }
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
}));

export default PnL;
import { WalletConnect } from '@layouts/WalletConnect';
import { Button, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { currencyFormat } from '@utils/Tools';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import theme from '../../Theme';


export default function BonusList(props) {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const { accessToken } = useSelector(state => state.general);
    const { modal } = WalletConnect();

    const [state, setState] = useState({ commissions: [] });

    useEffect(() => {
        setState({ commissions: props.commission.transaction })
    }, [props])
    

    const renderList = (nodes) => (
        <div key={nodes.id}>
            <div className="divider-dark m-tb-10"></div>
            <div>
                <div className='bor10 p-tb-10 w-full' style={{}}>
                    <div className='flex-sb-m w-full'>
                        <div>
                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.date')}</p>
                            <p className='fs-14 p-t-8 txt-truncate' style={{ maxWidth: 200, fontWeight: 500 }}>{nodes.bonus_date}</p>
                        </div>
                        <div>
                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.percent')}</p>
                            <p className='fs-14 p-t-8 txt-truncate' style={{ fontWeight: 500 }}>{currencyFormat(nodes.percent)}</p>
                        </div>
                        <div>
                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.rewardsEarned')}</p>
                            <div className='fs-14 p-t-8 txt-truncate' style={{ color: '#1CC871', fontWeight: 500 }}>
                                <p>{currencyFormat(nodes.amount, 4)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className='m-t-20 m-b-80 p-all-20 bor20' style={{ background: '#ffffff' }}>
            <p className='fs-16 p-all-5' style={{ fontWeight: 600 }}>{props.commission.name[i18n.resolvedLanguage]}</p>
            {
                (_.size(state?.commissions) > 0) ?
                    <div>
                        {_.map(state.commissions, node => (renderList(node)))}
                    </div>
                    :
                    <Card style={{ border: 'none', background: 'transparent' }} variant="outlined">
                        <CardContent className='txt-center' style={{ height: accessToken ? 400 : 100, borderRadius: 0 }}>
                            {
                                accessToken ?
                                    <div>
                                        <p className='fs-14 p-t-30' style={{ color: '#D0D5DD' }}>{t('table.noRecord')}</p>
                                    </div>
                                    :
                                    <div>
                                        <p className='fs-12 p-tb-16' style={{ color: theme.palette.gray.main, paddingTop: 16, fontWeight: 600 }}>{t('genealogy.connectWallet')}</p>
                                        <Button variant="contained" onClick={() => modal.open() } style={{ justifyContent: "flex-start" }}>
                                            <p className='p-l-10 fs-14'>{t('button.walletConnect')}</p>
                                        </Button>
                                    </div>
                            }
                        </CardContent>
                    </Card>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));
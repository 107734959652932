import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, TextField, FormControl, MenuItem, Typography, Drawer, InputLabel } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { storePaths } from '@utils/Tools';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { MdClose } from 'react-icons/md';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css/bundle';
import 'swiper/css/pagination';

export default function PurchasePlan() {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});
    const styles = useStyles();
    let navigate = useNavigate();
    let location = useLocation();
    const { accessToken } = useSelector(state => state.general);
    const { username } = useSelector(state => state.user);
    const [state, setState] = useState({
        packages: [],
        paymentMethods: [],
        wallet: [],
        securityPassword: "",
        confirmation: false,
        step: 1,
        planHistory: [],
        package_id: 1,
        package_image: '',
        combination_id: 1,
        roi_type: 'a',
        username: username
    });
    const [showPassword, setShowPassword] = useState({
        securityPassword: false,
    });
    const [drawerOpen, setDrawerOpen] = useState(null);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    useEffect(() => {
        setLoading(true);
        getUrl('/plan/setting').then(response => {
            if (response.status) {
                setState({ ...state, wallet: response.data.wallets, paymentMethods: response.data.payment_methods, packages: response.data.packages, package_id: response.data.packages[0].id, package_image: response.data.packages[0].plan_logo?.file_name });
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let package_data = state.packages.find((element) => element.id == state.package_id);
        if (package_data) {
            setState({ ...state, package_image: package_data?.plan_logo?.file_name })
        }
        // eslint-disable-next-line
    }, [state.package_id])


    const purchase = () => {
        setLoading(true);
        setInputErrors();
        let param = {
            package_id: state.package_id,
            combination_id: state.combination_id,
            roi_type: state.roi_type,
            username: state.username
            // security_password: state.securityPassword,
        };
        postUrl(`/purchase-plan`, param).then(response => {
            setLoading(false);
            if (response.status) {
                setDrawerOpen(null);
                setState({ ...state, securityPassword: '', step: 2, confirmation: false, planHistory: response.data });
                addAlert('', t('success.purchaseSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                let msg = response.message;
                _.map(response.errors, (value, index) => {
                    msg += "\n " + value;
                })
                addAlert('', msg || response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div className='p-t-90' style={{ minHeight: '100vh' }}>
            <div className='txt-center flex-col p-lr-20' style={{ gap: 8 }}>
                <Typography sx={{ fontSize: 36, fontWeight: 600 }}>{t('package.hubPlans')}</Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 400, color: theme.palette.gray.main }}>{t('package.hubPlansSubtitle')}</Typography>
            </div>
            {
                state.step === 1 ?
                <Swiper
                    slidesPerView={1.3}
                    navigation={true}
                    modules={[Navigation, Pagination]}
                    style={{ marginLeft: 10, marginTop: 30 }}
                    pagination={{
                        clickable: true,
                    }}
                    spaceBetween={20}
                    centeredSlides={true}
                >
                    {
                        _.map(state.packages, (_package) => {
                            let count=0;
                            return (
                                <SwiperSlide key={_package.id} style={{ paddingBottom: 40 }}>
                                    <div className='flex-col-c txt-center' style={{ background: "white", borderRadius: 20, padding: 24 }}>
                                        <Typography sx={{ fontSize: 20, fontWeight: 600 }}>{_package.name[i18n.language]}</Typography>
                                        <div className='flex-c-m'>
                                            { 
                                                _.map(_package.extra_setting, (_setting, _index) => {
                                                    count++;
                                                    return[
                                                        count>1&&<Divider key={_index+'divider'} orientation="vertical" variant="middle" flexItem style={{ marginLeft: 10, marginRight: 10 }} />,
                                                        <div key={_index}>
                                                            <Typography sx={{ fontSize: 20, fontWeight: 600 }}>{`${_setting.expired_after_days} ${t('package.days')}`}</Typography>
                                                            <Typography style={{ fontSize: 20, fontWeight: 600,color: theme.palette.gold.main }}>ROI {_setting.roi}%</Typography>
                                                        </div>
                                                    ]
                                                })
                                            }
                                        </div>
                                        {/* <div style={{marginTop: 10, width: 232, height: 120, backgroundColor: '#F1F5F6', borderRadius: 4 }}></div> */}
                                        <img src={`/images/plans/hub-${_package.id}.png`} style={{ marginTop: 10, width: 232, height: 120, borderRadius: 4 }} alt="plan" />
                                        <Typography style={{ fontSize: 12, color: theme.palette.gray.main, marginTop: 5 }}>{t('package.price')}</Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>{currencyFormat(_package.price, 0)} MKA</Typography>
                                        <Button fullWidth variant="contained" sx={{ mt: 1 }} onClick={() => accessToken ? [setState({...state, package_id: _package.id}), setDrawerOpen(_package)] : addAlert("", t('error.pleaseConnectNetwork')) }>{t('button.purchase')}</Button>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                :
                <div style={{ padding: 15 }}>
                    <div style={{ backgroundColor: '#d3efff', borderRadius: 15 }}>
                        <div style={{ paddingTop: 40, textAlign: 'center' }}>
                            {/* <img src="/images/background/Red_Double_Star.png" style={{ height: '10vh' }} alt="logo" /> */}
                            <p className='fs-18 p-t-24' style={{ fontWeight: 600 }}>{t('package.success')}</p>
                            <p className='p-t-4 fs-14' style={{ color: '#98A2B3' }}>{t('package.successDesc')}</p>
                        </div>
                        <div style={{ padding: '0 24px 10px', width: '100%' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Divider style={{ borderBottomWidth: '3px', paddingTop: 20, color: '#fff' }} />
                                <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                    <p className='fs-16'>{t('package.totalPrice')}</p>
                                    <p className='fs-16' style={{ fontWeight: 600 }}>{'$ ' + currencyFormat(state.planHistory.price, 2)}</p>
                                </Grid>
                                <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                    <p className='fs-16'>{t('package.dateTime')}</p>
                                    <p className='fs-16' style={{ fontWeight: 600 }}>{state.planHistory.created_at}</p>
                                </Grid>
                                <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                    <p className='fs-16'>{t('package.ref')}</p>
                                    <p className='fs-14' style={{ fontWeight: 600 }}>{state.planHistory.ulid}</p>
                                </Grid>
                                <div style={{ paddingBottom: 40, paddingTop: 20, textAlign: 'center' }}>
                                    <Button variant="contained" onClick={() => navigate('/home')} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.done')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Drawer
                anchor="bottom"
                open={drawerOpen ? true : false}
                onClose={() => setDrawerOpen(null)}
            >
                <div className='flex-col' style={{ padding: '20px', textAlign: 'center', backgroundColor: '#F1F5F6', height: '100%', paddingBottom: 40 }}>
                    <div className='flex-sb-m p-b-20'>
                        <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{t('package.hubPlanActivation')}</Typography>
                        <MdClose className='fs-24 pointer' onClick={() => setDrawerOpen(null)} />
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <TextField
                            label={t('user.username')}
                            variant="standard"
                            fullWidth
                            name='username'
                            placeholder={t('user.username')}
                            onChange={handleChange}
                            value={state.username}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            InputLabelProps={{ shrink: true }}
                        />
                        <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel id="package_roi_label">{t('package.selectedPlan')}</InputLabel>
                            <Select
                                labelId="package_roi_label"
                                label={t('package.selectedPlan')}
                                id="roi_type"
                                value={state.roi_type || ""}
                                name="roi_type"
                                onChange={handleChange}
                                sx={{
                                    color: '#000000 !important',
                                    backgroundColor: '#ffffff',
                                    border: '2px solid #ffffff',
                                    '&.selected': {
                                        color: '#000000'
                                    }
                                }}
                            >
                                {drawerOpen && _.map(drawerOpen?.extra_setting, (_setting, roi_code) => (
                                    <MenuItem key={roi_code} value={roi_code}>
                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                            <Typography>{drawerOpen?.name[i18n.language]} - {_setting.expired_after_days} {t('package.days')} - <span style={{ color: theme.palette.gold.main }}>ROI {_setting.roi}%</span></Typography>
                                        </div>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        
                        <FormControl fullWidth style={{ marginTop: 20 }}>
                            <InputLabel id="combination_id_label">{t('package.paymentMethod')}</InputLabel>
                            <Select
                                labelId="combination_id_label"
                                label={t('package.paymentMethod')}
                                id="combination_id"
                                value={state.combination_id || ""}
                                name="combination_id"
                                onChange={handleChange}
                                sx={{
                                    color: '#000000 !important',
                                    backgroundColor: '#ffffff',
                                    border: '2px solid #ffffff',
                                    '&.selected': {
                                        color: '#000000'
                                    }
                                }}
                            >
                                {
                                    _.map(state.paymentMethods, (wallets, combination_id) => {
                                        return (
                                            <MenuItem key={combination_id} value={combination_id}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                    <Typography className="p-r-5" style={{ color: theme.palette.gray.main }}>{t('package.price')}</Typography>
                                                    {
                                                        _.map(wallets, (percent, wallet_id) => {
                                                            let wallet = state.wallet.find((element) => element.id == wallet_id);
                                                            return (
                                                                <Typography key={combination_id + wallet.id} value={combination_id} className='txt-white p-r-5'>
                                                                    {' ' + (wallet?.name[i18n.resolvedLanguage] ?? wallet?.name['en']) + ' (' + percent + '%)'}
                                                                </Typography>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </div>
                    <div className="flex-sb" style={{ gap: 10 }}>
                        <Button fullWidth variant="contained" onClick={() => setDrawerOpen(null)} style={{ marginTop: 20, backgroundColor: '#D2EFFF', color: '#00A3FF' }}>{t('button.cancel')}</Button>
                        <Button fullWidth variant="contained" onClick={purchase} style={{ marginTop: 20 }}>{t('button.activate')}</Button>
                    </div>
                </div>
            </Drawer>
           
            {/* {
                state.step === 1 ?
                    <>
                        {
                            _.size(state.packages) > 0 ?
                                <div style={{ color: '#000000' }}>
                                    <div>
                                        <div className={styles.lowerPart}>
                                            <FormControl fullWidth>
                                                <Select
                                                    id="package_id"
                                                    value={state.package_id || ""}
                                                    name="package_id"
                                                    onChange={handleChange}
                                                    sx={{
                                                        color: '#fff !important',
                                                        backgroundColor: '#202e66',
                                                        border: '2px solid #ffffff',
                                                        '&.selected': {
                                                            color: '#000000'
                                                        }
                                                    }}
                                                >
                                                    {_.map(state.packages, (_package) => (
                                                        <MenuItem key={_package.id} value={_package.id}>{_package.name[i18n.resolvedLanguage] ?? _package.name['en']}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div className='p-t-15' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <p className='fs-20 txt-white' style={{ fontWeight: 600 }}>{t('package.totalPrice') + ': '}</p>
                                                {(_.size(state.packages) > 0 && state.package_id) &&
                                                    <p className='fs-20' style={{ color: theme.palette.primary.main, fontWeight: 600 }}>{'$ ' + currencyFormat(_.find(state.packages, { id: state.package_id })?.price, 2)}</p>
                                                }
                                            </div>
                                            <Divider style={{ borderBottomWidth: '3px', paddingTop: 20, color: '#fff' }} />
                                            <div className='p-t-10'>
                                                <p className='p-t-5 fs-20 txt-white' style={{ fontWeight: 600 }}>{t('package.paymentMethod')}</p>
                                                <FormControl fullWidth>
                                                    <Select
                                                        id="combination_id"
                                                        value={state.combination_id || ""}
                                                        name="combination_id"
                                                        onChange={handleChange}
                                                        sx={{
                                                            color: '#fff !important',
                                                            backgroundColor: '#202e66',
                                                            border: '1px solid #ffffff',
                                                            '&.selected': {
                                                                color: '#000000'
                                                            }
                                                        }}
                                                    >
                                                        {
                                                            _.map(state.paymentMethods, (wallets, combination_id) => {
                                                                return (
                                                                    <MenuItem key={combination_id} value={combination_id}>
                                                                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                            {
                                                                                _.map(wallets, (percent, wallet_id) => {
                                                                                    let wallet = state.wallet.find((element) => element.id == wallet_id);
                                                                                    return (
                                                                                        <Typography key={combination_id + wallet.id} value={combination_id} className='txt-white p-r-5'>
                                                                                            {' ' + (wallet?.name[i18n.resolvedLanguage] ?? wallet?.name['en']) + ' (' + percent + '%)'}
                                                                                        </Typography>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='p-tb-20'>
                                                <Button fullWidth variant="contained" onClick={() => setState({ ...state, confirmation: true })}>{t('button.purchase')}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </>
                    :
                    <div style={{ padding: 15 }}>
                        <div style={{ backgroundColor: '#202E66', borderRadius: 15 }}>
                            <div style={{ paddingTop: 40, textAlign: 'center' }}>
                                <img src="/images/background/Red_Double_Star.png" style={{ height: '10vh' }} alt="logo" />
                                <p className='fs-18 p-t-24 txt-white' style={{ fontWeight: 600 }}>{t('package.success')}</p>
                                <p className='p-t-4 fs-14' style={{ color: '#98A2B3' }}>{t('package.successDesc')}</p>
                            </div>
                            <div style={{ padding: '0 24px 10px', width: '100%' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Divider style={{ borderBottomWidth: '3px', paddingTop: 20, color: '#fff' }} />
                                    <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                        <p className='fs-16 txt-white'>{t('package.totalPrice')}</p>
                                        <p className='fs-16 txt-white' style={{ fontWeight: 600 }}>{'$ ' + currencyFormat(state.planHistory.price, 2)}</p>
                                    </Grid>
                                    <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                        <p className='fs-16 txt-white'>{t('package.dateTime')}</p>
                                        <p className='fs-16 txt-white' style={{ fontWeight: 600 }}>{state.planHistory.created_at}</p>
                                    </Grid>
                                    <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                                        <p className='fs-16 txt-white'>{t('package.ref')}</p>
                                        <p className='fs-14 txt-white' style={{ fontWeight: 600 }}>{state.planHistory.ulid}</p>
                                    </Grid>
                                    <div style={{ paddingBottom: 40, paddingTop: 20, textAlign: 'center' }}>
                                        <Button variant="contained" onClick={() => navigate('/home')} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.done')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            } */}
            <Dialog open={state.confirmation} onClose={() => setState({ ...state, confirmation: false })}>
                <DialogTitle style={{ paddingTop: 40, textAlign: 'center' }}>
                    <p className='fs-18' style={{ fontWeight: 600 }}>{t('package.confirmation')}</p>
                    <p className='p-t-4 fs-14' style={{ color: '#98A2B3' }}>{t('package.confirmationDetails')}</p>
                </DialogTitle>
                <DialogContent style={{ padding: '0 24px 10px', width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <Grid container display="flex" justifyContent="space-between" className='p-t-24'>
                            <p className='fs-16 txt-white'>{t('package.quantity')}</p>
                            <p className='fs-16 txt-white' style={{ fontWeight: 600 }}>{1}</p>
                        </Grid>
                        <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                            <p className='fs-16 txt-white'>{t('package.totalPrice')}</p>
                            <p className='fs-16 txt-white' style={{ fontWeight: 600 }}>{'$ ' + currencyFormat(_.find(state.packages, { id: state.package_id })?.price, 2)}</p>
                        </Grid>
                        <Grid container display="flex" justifyContent="space-between" className='p-t-16'>
                            <p className='fs-16 txt-white'>{t('package.paymentMethod')}</p>
                            {/* {
                                _.map(state.paymentMethods, (data, id) => {
                                    let wallet = _.find(state.wallet, (item) => {
                                        return _.find(item, id)
                                    });
                                    return (
                                        <p key={wallet.id} className='fs-16 txt-white' style={{ fontWeight: 600 }}>{wallet.name[i18n.resolvedLanguage]}</p> // + ': ' + data[id]
                                    )
                                })
                            } */}
                            <div>
                                {
                                    _.map(state.paymentMethods, (wallets, combination_id) => {
                                        return (
                                            <div key={combination_id}>
                                                {
                                                    combination_id == state.combination_id ?
                                                        _.map(wallets, (percent, wallet_id) => {
                                                            let wallet = state.wallet.find((element) => element.id == wallet_id);
                                                            return (
                                                                <p key={combination_id + wallet.id} value={combination_id} className='fs-16 txt-white p-r-5'>
                                                                    {' ' + (wallet?.name[i18n.resolvedLanguage] ?? wallet?.name['en']) + ' (' + percent + '%)'}
                                                                </p>
                                                            )
                                                        })
                                                        : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </div>
                    <p className='fs-16 txt-white p-t-40'>{t('user.securityPassword')}</p>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='securityPassword'
                        placeholder={t('user.securityPassword')}
                        onChange={handleChange}
                        type={showPassword?.securityPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handleClickShowPassword('securityPassword')}>
                                        {showPassword?.securityPassword ? <MdVisibility className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <MdVisibilityOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                        error={inputErrors && inputErrors.security_password ? true : false}
                        InputLabelProps={{ shrink: true }}
                    />
                </DialogContent>
                <DialogActions style={{ width: 'calc(100% - 39px)', paddingBottom: 40 }}>
                    <Button variant="outlined" onClick={() => setState({ ...state, confirmation: false })} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={purchase} style={{ paddingTop: 10, paddingBottom: 10 }}>{t('button.confirm')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    upperPart: {
        padding: '0 0 10px',
        width: 'auto'
    },
    lowerPart: {
        backgroundColor: '#202E66',
        borderRadius: '24px 24px 0px 0px',
        border: '1px solid',
        padding: '10px 30px',
        position: 'absolute',
        bottom: 0,
        height: '70%',
        top: 381,
        width: '100%',
        maxWidth: '444px',
    }
}));
import { WalletConnect } from '@layouts/WalletConnect';
import { Button, Checkbox, Drawer, FormControlLabel } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, putUrl } from '@utils/ApiAction';
import { currencyFormat } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FiPlus, FiX } from 'react-icons/fi';

export default function PlanHistories() {
    const [state, setState] = useState({
        ongoing: [],
        completed: [],
        benefits: [],
        userRank: [],
        display: 'ongoing'
    });
    const [claimPercent, setClaimPercent] = useState(0);
    const [checked, setChecked] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { modal } = WalletConnect();
    const { accessToken } = useSelector(state => state.general);

    //Drawer
    const [claimDialog, setClaimDialog] = useState({
        open: false,
        data: []
    });
    const [reactiveDialog, setReactiveDialog] = useState({
        open: false,
        data: []
    });
    const handleClaimClickOpen = (data) => {
        setClaimDialog({ ...claimDialog, open: true, data: data });
    };
    const handleClaimClose = () => {
        setChecked(false);
        setClaimDialog({ ...claimDialog, open: false, data: [] });
    };

    const handleReactiveClickOpen = (data) => {
        setReactiveDialog({ ...reactiveDialog, open: true, data: data });
    };
    const handleReactiveClose = () => {
        setChecked(false);
        setReactiveDialog({ ...reactiveDialog, open: false, data: [] });
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (claimDialog.data?.details?.['refund']) {
            const highestPercent = _.reduce(claimDialog.data?.details?.['refund'], (result, percent, date) => {
                if (new Date(date) - new Date() < 0) {
                    return Math.max(result, percent);
                }
                return result;
            }, 0);
            setClaimPercent(highestPercent);
        } else {
            setClaimPercent(0);
        }
    }, [claimDialog.data]);

    useEffect(() => {
        if (accessToken) {
            isMountedRef.current = true;
            setLoading(true);
            getUrl('/user/plan-histories').then(response => {
                if (response.status && isMountedRef.current) {
                    setState({ ...state, ongoing: response.data.ongoing, completed: response.data.completed, benefits: response.data.benefits, userRank: response.data.user_rank });
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
            return () => isMountedRef.current = false;
        }
        // eslint-disable-next-line
    }, [state.pageIndex, accessToken, refresh]);

    const redeemPlan = (id) => {
        setLoading(true);
        putUrl(`/user/plan-histories/redeem/${id}`).then(response => {
            if (response.status) {
                addAlert('', t('success.claimSuccess'), 'success', '');
                setRefresh(!refresh);
                handleClaimClose();
            } else {
                addAlert('', response.message || t('error.claimError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const reactivePlan = (id) => {
        setLoading(true);
        putUrl(`/user/plan-histories/reactive/${id}`).then(response => {
            if (response.status) {
                addAlert('', t('success.reactiveSuccess'), 'success', '');
                setRefresh(!refresh);
                handleReactiveClose();
            } else {
                addAlert('', response.message || t('error.reactiveError'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <div className='p-t-90 p-lr-20' style={{ minHeight: '100vh' }}>
            <div className='txt-center flex-col' style={{ gap: 8 }}>
                <p style={{ fontSize: 36, fontWeight: 600 }}>{t('title.myHub')}</p>
                <p className='p-t-10' style={{ fontSize: 14, fontWeight: 400, color: theme.palette.gray.main }}>{t('package.myHubDesc')}</p>
            </div>
            <div className="bor20 m-t-30" style={{ backgroundColor: '#27272E' }}>
                <div>
                    <p className="p-lr-20" style={{ color: 'white', paddingTop: 20 }}>{t('package.myBenefits', { plan: state.userRank?.name?.[i18n.resolvedLanguage || 'en'] || 'HUB' })}</p>
                    <div className='w-full p-t-20 p-l-10' style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {
                            _.map(state.benefits, (percent, name) => {
                                return (
                                    <div key={name} className='flex-m w-full m-all-5' style={{ background: '#363639', borderRadius: 8, width: 'fit-content', minHeight: 28 }}>
                                        <p className='flex-m fs-11 txt-white p-lr-5'><FiPlus className='m-r-5' style={{ color: theme.palette.primary.main }} />{(percent > 0 ? percent + '% ' : '') + name}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="p-b-20 m-b-60" style={{ marginTop: 10, borderRadius: 20, backgroundColor: 'white' }}>
                    <div className='flex-m p-all-20  fs-14' style={{ gap: 20 }}>
                        <p className='pointer' style={{ color: state.display == 'ongoing' ? '#11162B' : '#7A8AA0', fontWeight: 600 }} onClick={() => setState({ ...state, display: 'ongoing' })}>{t('package.ongoing')} ({_.size(state.ongoing)})</p>
                        <p className='pointer' style={{ color: state.display == 'completed' ? '#11162B' : '#7A8AA0', fontWeight: 600 }} onClick={() => setState({ ...state, display: 'completed' })}>{t('package.completed')} ({_.size(state.completed)})</p>
                    </div>
                    {
                        accessToken ?
                            <>
                                {
                                    _.size(state.display == 'ongoing' ? state.ongoing : state.completed) > 0 ?
                                        <>
                                            {
                                                _.map(state.display == 'ongoing' ? state.ongoing : state.completed, (detail, index) => {
                                                    let difference = new Date(detail.expired_date) - new Date();
                                                    let timeLeft = {};
                                                    if (difference > 0) {
                                                        timeLeft = {
                                                            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                                                            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                                                            minutes: Math.floor((difference / 1000 / 60) % 60),
                                                        };
                                                    }
                                                    return (
                                                        <div key={index}>
                                                            <div className="divider-dark m-l-20" style={{ width: '90%' }}></div>
                                                            <div className='bor10 p-t-15 w-full' style={{}}>
                                                                <div className='flex-sb-m w-full p-lr-20 p-b-20'>
                                                                    <div style={{ width: '50%' }}>
                                                                        <p className='fs-12 txt_truncate2'>{detail.ulid}</p>
                                                                        <p className='fs-14 p-t-8 txt-upper' style={{ maxWidth: 200, fontWeight: 500 }}>{detail.plan?.name?.[i18n.resolvedLanguage || 'en'] || '-'}</p>
                                                                    </div>
                                                                    <div className='p-r-20'>
                                                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('package.endsIn')}</p>
                                                                        {
                                                                            state.display == 'ongoing' ?
                                                                                <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{timeLeft.days + 'd : ' + timeLeft.hours + 'h : ' + timeLeft.minutes + 'm'}</p>
                                                                                :
                                                                                <p className='fs-14 p-t-8' style={{ color: theme.palette.primary.main, fontWeight: 500 }}>{t('package.completed')}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    _.includes([20, 30], detail.status) &&
                                                                    <div className='w-full flex-sa-m p-lr-20 p-b-20' style={{ gap: 20 }}>
                                                                        <Button variant='contained' className={detail.status == 20 ? 'btn-red' : 'btn-green'} fullWidth disabled={new Date() - new Date(detail?.details['min_lock_day']) < 0} sx={{ height: 40, width: '50%' }} onClick={() => handleClaimClickOpen(detail)}>{detail.status == 20 ? t('package.terminate') : t('package.withdraw')}</Button>
                                                                        <Button variant='contained' fullWidth sx={{ width: '50%' }} disabled={detail.status != 30} onClick={() => handleReactiveClickOpen(detail)}>{t('button.reactive')}</Button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </>
                                        :
                                        <div className='txt-center' style={{ paddingTop: 40 }}>
                                            {/* <img src='/images/general/no-transaction.png' alt='no-transaction' style={{ width: 180 }} loading='lazy' /> */}
                                            <p className='fs-16' style={{ paddingTop: 10, fontWeight: 600 }}>{t('table.noRecord')}</p>
                                        </div>
                                }
                            </>
                            :
                            <div>
                                <div className="divider-dark m-l-20" style={{ width: '90%' }}></div>
                                <div className='flex-col-c-m p-t-20' style={{ gap: 20 }}>
                                    <p className='fs-12' style={{ color: theme.palette.gray.main }}>{t('package.loginDesc')}</p>
                                    <Button variant="contained" onClick={() => modal.open()} style={{ justifyContent: "flex-start" }}>
                                        <p className='p-lr-10 fs-14'>{t('button.walletConnect')}</p>
                                    </Button>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <Drawer
                open={claimDialog.open}
                anchor="bottom"
                onClose={handleClaimClose}
            >
                <div className='w-full p-all-30'>
                    <div className='fs-15 flex-sb-m'>
                        <p className='fs-18 txt-title p-t-10' style={{ fontWeight: '600' }}>{claimDialog.data.status == 20 ? t('package.contractTermination') : t('package.withdraw')}</p>
                        <FiX className='fs-23 clblack pointer' onClick={handleClaimClose} />
                    </div>
                    <div className='p-t-30'>
                        <div className='fs-14' style={{ fontWeight: 500, color: theme.palette.textColor }}>
                            <div className='flex-sb-m'>
                                <p>{t('package.contractValue')}</p>
                                <p>{currencyFormat(claimDialog.data?.price, 2)}</p>
                            </div>
                        </div>
                    </div>
                    <p className='p-t-20 fs-12' style={{ color: theme.palette.gray.main }}>{t('package.willReceive')}</p>
                    <div className='p-t-20'>
                        {
                            claimDialog.data?.status == 20 &&
                            <div className='fs-14 p-b-10' style={{ fontWeight: 500, color: theme.palette.red.main }}>
                                <div className='flex-sb-m'>
                                    <p>{t('package.penalty', { percent: (100 - claimPercent) })}</p>
                                    <p>- {currencyFormat(claimDialog.data?.price * ((100 - claimPercent) / 100), 2)}</p>
                                </div>
                            </div>
                        }
                        <div className='fs-14' style={{ fontWeight: 500, color: theme.palette.textColor }}>
                            {
                                claimDialog.data?.status == 30 ? // inactive
                                    <div className='flex-sb-m'>
                                        <p>{t('package.withdrawAmount')}</p>
                                        <p>{currencyFormat(claimDialog.data?.price, 2)}</p>
                                    </div>
                                    :
                                    <div className='flex-sb-m'>
                                        <p>{t('package.capitalRefund', { percent: claimPercent })}</p>
                                        <p>{currencyFormat(claimDialog.data?.price * (claimPercent / 100), 2)}</p>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="divider-dark w-full m-t-20"></div>
                    {
                        claimDialog.data.status == 20 &&
                        <div className='p-t-20'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={handleChange}
                                    />
                                }
                                label={t('package.penaltyDesc')}
                                sx={{
                                    '& .MuiFormControlLabel-label': {
                                        fontSize: '12px',
                                        color: theme.palette.gray.main,
                                        fontWeight: 500,
                                    },
                                }}
                            />
                        </div>
                    }
                    <div className='w-full flex-sa-m p-t-20' style={{ gap: 20 }}>
                        <Button variant='contained' fullWidth className='btn-lightBlue' onClick={handleClaimClose}>{t('button.back')}</Button>
                        <Button variant='contained' fullWidth disabled={!checked && claimDialog.data.status == 20} className={claimDialog.data.status == 20 ? 'btn-red' : 'btn-green'} onClick={() => redeemPlan(claimDialog.data?.id)}>{claimDialog.data.status == 20 ? t('package.terminate') : t('package.withdraw')}</Button>
                    </div>
                </div>
            </Drawer>
            <Drawer
                open={reactiveDialog.open}
                anchor="bottom"
                onClose={handleReactiveClose}
            >
                <div className='w-full p-all-30'>
                    <div className='fs-15 flex-sb-m'>
                        <p className='fs-18 txt-title p-t-10' style={{ fontWeight: '600' }}>{t('package.reactive')}</p>
                        <FiX className='fs-23 clblack pointer' onClick={handleReactiveClose} />
                    </div>
                    <p className='p-t-20 fs-14 lh-15' style={{ color: theme.palette.gray.main }}>{t('package.reactiveDesc')}</p>
                    <div className="divider-dark w-full m-t-20"></div>
                    <div className='w-full flex-sa-m p-t-20' style={{ gap: 20 }}>
                        <Button variant='contained' fullWidth className='btn-lightBlue' onClick={handleReactiveClose}>{t('button.back')}</Button>
                        <Button variant='contained' fullWidth disabled={!checked && reactiveDialog.data.status == 20} className='btn-green' onClick={() => reactivePlan(reactiveDialog.data?.id)}>{t('button.reactive')}</Button>
                    </div>
                </div>
            </Drawer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));
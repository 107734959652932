import React, { useEffect, useState } from 'react';
import { Button, Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { useTheme } from '@mui/styles';
import { useSelector } from 'react-redux';
import useNotificationLoading from '@utils/useNotificationLoading';
import { loginGame, postUrl } from '@utils/ApiAction';

const GameDrawer = ({ drawerOpen, setDrawerOpen, gameData, submitData }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const { accessToken } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();

    const loginGameAccess = (miniGameCode) => {
        if (accessToken) {
            setLoading(true);
            let params = {
                'game_code': 'minigames',
                'mini_game_code': miniGameCode
            }
            postUrl(`/game-login`, params).then(response => {
                if (response.status) {
                    try {
                        let url = new URL(response.data.url + '?token=' + response.data.token);
                        window.open(url.toString(), "_self");
                    } catch (error) {
                        addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    }
                } else {
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch((error) => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    // const redirectGame = (code) => {
    //     let stringUrl = process.env.REACT_APP_GAME_URL;
    //     let modifiedUrl = stringUrl.replace("//", `//${code}.`);
    //     let url = new URL(modifiedUrl+'?token='+gameToken);
    //     window.open(url.toString(), "_blank", "noreferrer");
    // }

    return (
        <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
        >
            <div className='p-b-40' style={{ width: 'auto', padding: '20px', textAlign: 'center' }}>
                <div className='flex-sb-m p-b-20'>
                    <p></p>
                    <p className='clwhite fs-16 p-l-20 txt-title'>{gameData.name}</p>
                    <MdClose className='fs-24 pointer' onClick={() => setDrawerOpen(false)} />
                </div>
                {/* <div className='flex-c-m' style={{ borderRadius: 16, border: '1px solid #242424', width: 120, height: 120 }}> */}
                <div style={{ borderRadius: 20, border: '1px solid #303030', width: 120, height: 120, margin: '0 auto' }}>
                    <img src={`/images/game/${gameData.code}.png`} alt='game' />
                </div>
                {/* </div> */}
                <div className='p-t-20'>
                    <Button
                        className='w-full btn-lightBlue'
                        style={{ color: theme.palette.primary.main, borderRadius: 16, height: 56 }}
                        disabled={!gameData.play}
                        onClick={() => loginGameAccess(gameData.code)}
                    >
                        <p className='flex-m txt-title fs-20'>{gameData.play ? t('games.play') : t('games.soon')}</p></Button>
                </div>
                <div className='w-full txt-left p-b-100'>
                    <p className='m-t-20'>{t('games.abouts')}</p>
                    <p className='m-t-10 fs-14 lh-13' style={{ color: '#5E5E5E' }}>{gameData.descr?.[i18n.resolvedLanguage || 'en'] || '-'}</p>
                </div>
            </div>
        </Drawer>
    )
}

export default GameDrawer;
import { useEffect, useState } from 'react';
import { WalletConnect } from '@layouts/WalletConnect';
import { Button, InputAdornment, TextField, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Divider } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl, postUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { ethers } from 'ethers';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { currencyFormat } from '@utils/Tools';
import moment from "moment";

import { FaArrowDown } from "react-icons/fa6";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';


export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { username, email, crypto_address, web_three } = useSelector(state => state.user);
    const { ABI, chain, chainId, provider, hexChainId, modal } = WalletConnect();
    const { walletProvider } = useWeb3ModalProvider();
    let { wallet } = useParams();

    const [info, setInfo] = useState({
        balances: [],
        allowances: [],
        rules: [],
        companyAddress: '',
        contractAddress: '',
        rate: 0,
        marketRate: 0,
        mkcPurchases: [],
        walletBalances: null,
        totalUsdtSpent: 0,
    });
    const [state, setState] = useState({
        pay: '',
        receive: 0,
        swap: wallet,
    })
    const [insufficientBalance, setInsufficientBalance] = useState(false);
    const [open, setOpen] = useState(null);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'pay') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            let receive = value / info.rate;
            setState({ ...state, [name]: value, receive: Math.floor(receive*10000)/10000 });
        } else if (name == 'receive') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            let pay = value * info.rate;
            setState({ ...state, [name]: value, pay: pay });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    useEffect(() => {
        // setState({ ...state, receive: state.pay / info.rate });
        if (Number(state.pay || 0) > Number(info.balances['usdt'] || 0) || state.pay < state.rules?.min_amount) {
            setInsufficientBalance(true);
        } else {
            setInsufficientBalance(false);
        }
        // eslint-disable-next-line
    }, [state.swap, state.pay, state.rules, info])

    const getSwapSetting = () => {
        getUrl('/wallets/swap-setting', { wallet: wallet }).then(response => {
            if (response.status) {
                setInfo({
                    ...info,
                    balances: response.data?.balances,
                    allowances: response.data?.allowances,
                    rules: response.data?.deposit_rules,
                    companyAddress: response.data?.company_address,
                    contractAddress: response.data?.contract_address,
                    rate: response.data?.rate,
                    marketRate: response.data?.market_rate,
                    mkcPurchases: response.data?.mkcPurchases,
                    walletBalances: _.find(response.data?.wallet_balances, {code: 'mkc'}),
                    totalUsdtSpent: response.data?.total_usdt_spent
                });
            } else {
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);

        getSwapSetting();

        // eslint-disable-next-line
    }, [accessToken, wallet])

    const setMaxAmount = (wallet) => {
        let max = 0;
        let maxReceive = 0;
        if (wallet != 'usdt') {
            max = info.balances['usdt'];
            maxReceive = info.balances['usdt'] / info.rate;
        } else {
            max = info.balances[wallet];
            maxReceive = info.balances[wallet] / info.rate;
        }
        setState({ ...state, pay: Math.floor(max * 100) / 100, receive: Math.floor(maxReceive * 100) / 100 });
    }

    const submit = async () => {
        setLoading(true);
        const provider = walletProvider ? new ethers.BrowserProvider(walletProvider) : new ethers.BrowserProvider(window.ethereum);
        if (provider /* && info.companyAddress */) {
            const getAllowance = async () => {
                try {
                    const response = await getUrl(`/wallets/allowance/usdt`);
                    if (response.status) {
                        if (Number(state.pay) <= Number(response.data)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        addAlert('', response.message || t('error.contactSupport'), 'error', '');
                        return true;
                    }
                } catch (error) {
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                    return true;
                };
            }
            const allowanceResponse = await getAllowance();
            if (!allowanceResponse) { // if not enough allowance do approve action
                // change chain if not connected to correct chain
                if (chain['id'] != chainId) {
                    try {
                        await provider.send("wallet_switchEthereumChain", [{ chainId: hexChainId }]);
                    } catch (error) {
                        addAlert('', `Please change your wallet connect network to ${chain['name']}`, 'error', '');
                        setLoading(false);
                        return;
                    }
                }
                try {
                    let signer = await provider.getSigner();
                    let contract = new ethers.Contract(info.contractAddress, ABI.usdt, signer);
                    let tx = await contract.approve(info.companyAddress, ethers.MaxUint256);
                    let receipt = await provider.waitForTransaction(tx.hash);
                    if (!receipt.status) {
                        addAlert('', t('error.approveAllowanceError'), 'error', '');
                        setLoading(false);
                        return;
                        // console.log("contract approve Failed", receipt);
                    }
                } catch (error) {
                    const revertReason = (error.reason || error.message) || t('error.transactionError');
                    addAlert('', revertReason, 'error', '');
                    setLoading(false);
                    return;
                }
            }
            let params = {
                "wallet_code": wallet,
                "amount": state.receive
            };
            postUrl(`/wallet/swap-token`, params).then(response => {
                setLoading(false);
                if (response.status) {
                    setState({...state, pay: '', receive: 0});
                    addAlert('', t('swaps.successfully'), 'success', '');
                    getSwapSetting();
                } else {
                    let msg = response.message;
                    _.map(response.errors, (value, index) => {
                        msg += "\n\n " + value;
                    })
                    addAlert('', msg || response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setLoading(false);
            addAlert('', 'Wallet Connect is not installed', 'error', '');
        }
    }

    const displayNextRelease = (_detail) => {
        let elements = [
            <div key={'first'} className="flex-sb-m">
                <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{_detail?.details?.next_release_date}</Typography>
                <Typography style={{ fontSize: 14, textAlign: 'right' }}>{currencyFormat(_detail?.details?.percent*_detail?.details?.balance/100, 4)} (~ {currencyFormat(_detail?.details?.percent*_detail?.details?.balance/100*info.marketRate, 2)})</Typography>
            </div>
        ];
        let rules = info.rules.unlock_rules.subsequent;
        let percent= rules.percent;
        let days = rules.days;
        let currentBalance = _detail.details.balance-(_detail.details.balance*_detail.details.percent/100);
        let nextReleaseDate = moment(_detail?.details?.next_release_date);
        for (var i=1;i<=9; i++) {
            let releaseAmount = currentBalance*percent/100;
            currentBalance = currentBalance-releaseAmount;
            nextReleaseDate = nextReleaseDate.add(days, 'days');
            elements.push(
                <div key={i} className="flex-sb-m">
                    <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{nextReleaseDate.local().format('YYYY-MM-DD')}</Typography>
                    <Typography style={{ fontSize: 14, textAlign: 'right' }}>{currencyFormat(releaseAmount, 4)} (~ {currencyFormat(releaseAmount*info.marketRate, 2)})</Typography>
                </div>
            )
        }
        return elements;
    }

    return (
        <div className='app-header-margin'>
            <div className='flex-col flex-c-m p-b-30'>
                <p className='fs-36' style={{ fontWeight: 600 }}>{wallet == 'mka' ? t('title.swapMKA') : t('title.swapMKC')}</p>
                <p className='fs-16 p-t-10' style={{ color: '#7A8AA0' }}>{t('swaps.buyTokenDesc', { token: wallet.toUpperCase() })}</p>
            </div>
            <div className='p-all-20 flex-col-c bor20' style={{ background: '#FFFFFF' }}>
                <div className="w-full flex-col p-t-20">
                    <div className='w-full'>
                        <TextField
                            variant="standard"
                            fullWidth
                            name='pay'
                            type="number"
                            placeholder={t('swaps.tokenAmount', { token: 'USDT' })}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            value={state.pay}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" className='flex-c-m p-tb-24 p-lr-10' sx={{ borderRight: '1px solid #000A3D24', width: '80px !important' }}>
                                        <p className='txt-upper fs-14' style={{ color: '#00A3FF' }}>USDT</p>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" className='flex-c-m p-r-10' >
                                        <Button variant='standard' className='fs-12' sx={{ borderRadius: '6px', background: '#D2EFFF !important', color: theme.palette.button.main }} onClick={() => setMaxAmount('usdt')}>
                                            {t('swaps.max')}
                                        </Button>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                height: 72,
                                input: {
                                    fontSize: '14px',
                                    height: '72px !important',
                                    color: insufficientBalance ? '#ff2020' : '#5E5E5E'
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="flex-c-m" style={{ zIndex: 3, background: '#00A3FF', borderRadius: 100, color: '#ffffff' }}>
                    <FaArrowDown className='fs-14 m-all-4' />
                </div>
                {/* <div className="w-full flex-col p-all-20">
                    <div className='flex-sb-m p-t-20'>
                        <p className='fs-30' style={{ color: state.receive > 0 ? '#FFFFFF' : '#5E5E5E' }}>{currencyFormat(state.receive || 0, 2)}</p>
                        <div className='flex-m'>
                            <p className='txt-upper clwhite fs-14 m-l-5'>{type}</p>
                        </div>
                    </div>
                </div> */}
                <div className='w-full p-t-15'>
                    <TextField
                        variant="standard"
                        fullWidth
                        name='receive'
                        type="number"
                        placeholder={t('swaps.tokenAmount', { token: wallet.toUpperCase() })}
                        onChange={ (e) => {
                            let value = e.target.value;
                            // Limit to 4 decimal places
                            if (value.includes('.')) {
                                const [intPart, decPart] = value.split('.');
                                value = decPart.length > 4 ? `${intPart}.${decPart.slice(0, 4)}` : value;
                            }

                            handleChange({ target: { name: e.target.name, value } });
                        }}
                        InputLabelProps={{ shrink: true }}
                        value={state.receive}
                        InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start" className='flex-c-m p-tb-24 p-lr-14' sx={{ borderRight: '1px solid #000A3D24' }}>
                                    <p className='txt-upper fs-14' style={{ color: '#00A3FF' }}>{wallet}</p>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" className='flex-c-m p-r-10' >
                                    <Button variant='standard' className='fs-12' sx={{ borderRadius: '6px', background: '#D2EFFF !important', color: theme.palette.button.main }} onClick={() => setMaxAmount(wallet)}>
                                        {t('swaps.max')}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            height: 72,
                            input: {
                                fontSize: '14px',
                                height: '72px !important',
                                color: insufficientBalance ? '#ff2020' : '#5E5E5E'
                            }
                        }}
                    />
                </div>
                <div className='p-t-20 w-full'>
                    {
                        !accessToken ?
                            <Button variant='contained' fullWidth style={{ borderRadius: 16, minHeight: 56 }} sx={{ padding: '20px 0 20px 0' }} onClick={() => modal.open()}><p className='flex-m txt-title fs-18'>{t('general.connectWallet')}</p></Button>
                            :
                            <Button variant='contained' fullWidth disabled={insufficientBalance || state.pay <= 0} sx={{ padding: '20px 0 20px 0' }} onClick={submit}><p >{t('swaps.swapNow')}</p></Button>
                    }
                </div>
                <div className='w-full flex-sb-m p-t-10 fs-12' style={{ color: '#7A8AA0' }}>
                    <p>{t('swaps.rate')}</p>
                    <p className='txt-upper'>1 USDT = {info.rate ? (1 / info.rate) : 0} {wallet}</p>
                </div>
            </div>

            { (accessToken && wallet === 'mkc') &&

                <div className="" style={{ marginTop: 10, borderRadius: 20, backgroundColor: 'white' }}>
                    <div className="p-t-20 p-b-10">
                        <div className='flex-l w-full p-lr-20 p-b-10'>
                            <div style={{ width: '50%' }}>
                                <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.marketRate')}</p>
                                <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{info?.marketRate}</p>
                            </div>
                            <div className=''>
                                <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.totalUsdtSpent')}</p>
                                <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{info?.totalUsdtSpent}</p>
                            </div>
                        </div>
                        <div className='flex-l w-full p-lr-20 p-b-10'>
                            <div style={{ width: '50%' }}>
                                <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.totalMkc')}</p>
                                <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{info?.walletBalances?.balance}</p>
                            </div>
                            <div className=''>
                                <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.totalMkcValue')}</p>
                                <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>~ {currencyFormat(info?.walletBalances?.balance*info.marketRate, 2)} USDT</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            { (accessToken && wallet === 'mkc') ?
            <div className="p-b-20 m-b-60" style={{ marginTop: 10, borderRadius: 20, backgroundColor: 'white' }}>
                <div className='flex-m p-all-20  fs-14' style={{ gap: 20 }}>
                    {/* <p className='pointer' style={{ color: '#11162B', fontWeight: 600 }} >{t('wallet.openOrder')}</p>
                    <p className='pointer' style={{ color: '#7A8AA0', fontWeight: 600 }} >{t('wallet.holding')}</p> */}
                    <p className='pointer' style={{ color: '#11162B', fontWeight: 600 }} >{t('wallet.history')}</p>
                </div>
                <>
                    {
                        _.size(info.mkcPurchases) > 0 ?
                            <>
                                {
                                    _.map(info.mkcPurchases, (detail, index) => {
                                        {/* let cdate = new Date(detail.created_at);
                                        let matureDate = new Date(cdate);
                                        matureDate.setDate(matureDate.getDate() + 90);
                                        let difference = new Date(matureDate) - new Date();
                                        let timeLeft = {};
                                        if (difference > 0) {
                                            timeLeft = {
                                                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                                                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                                                minutes: Math.floor((difference / 1000 / 60) % 60),
                                            };
                                        } */}
                                        return (
                                            <div key={index}>
                                                <div className="divider-dark m-l-20" style={{ width: '90%' }}></div>
                                                <div className='bor10 p-t-15 w-full' style={{}}>
                                                    <div className='flex-l w-full p-lr-20 p-b-10'>
                                                        <div style={{ width: '50%' }}>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.orderId')}</p>
                                                            <div className="flex-l-m">
                                                                <p className='fs-14' style={{ maxWidth: 200, fontWeight: 500 }}>#{detail.id}</p>
                                                                <IconButton
                                                                    aria-label="close"
                                                                    onClick={() => setOpen(detail)}
                                                                    sx={{
                                                                        color: (theme) => theme.palette.grey[500],
                                                                        padding: 0,
                                                                        paddingLeft: 1
                                                                    }}
                                                                >
                                                                    <VisibilityIcon />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('general.createdAt')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{moment(detail.created_at).local().format('YYYY-MM-DD')}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex-l w-full p-lr-20 p-b-10'>
                                                        <div style={{ width: '50%' }}>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.rate')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{detail?.details?.system_rate}</p>
                                                        </div>
                                                        <div className=''>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.price')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{(detail?.details?.system_rate * detail.final_amount)}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex-l w-full p-lr-20 p-b-10'>
                                                        <div style={{ width: '50%' }}>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>MKC {t('wallet.amount')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{currencyFormat(detail.final_amount,4)}</p>
                                                        </div>
                                                        <div className=''>
                                                           {/* <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.unlockIn')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{timeLeft.days + 'd : ' + timeLeft.hours + 'h : ' + timeLeft.minutes + 'm'}</p> */}
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.nextRelease')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{detail?.details?.next_release_date}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex-l w-full p-lr-20 p-b-10'>
                                                        <div style={{ width: '50%' }}>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.releasedAmount')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{currencyFormat(detail?.final_amount-detail?.details?.balance,4)}</p>
                                                        </div>
                                                        <div className=''>
                                                            <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('wallet.lockedAmount')}</p>
                                                            <p className='fs-14 p-t-8' style={{ maxWidth: 200, fontWeight: 500 }}>{currencyFormat(detail?.details?.balance, 4)}</p>
                                                        </div>
                                                    </div>
                                                    {/* {
                                                        _.includes([20, 30], detail.status) &&
                                                        <div className='w-full flex-sa-m p-lr-20 p-b-20' style={{ gap: 20 }}>
                                                            <Button variant='contained' className='btn-green' fullWidth disabled={new Date() - new Date(detail?.details['min_lock_day']) < 0} sx={{ height: 40, width: '50%' }} >{detail.status == 20 ? t('package.claimEarly') : t('package.claim')}</Button>
                                                            <Button variant='contained' fullWidth sx={{ width: '50%' }} disabled={detail.status != 30} >{t('package.reactive')}</Button>
                                                        </div>
                                                    } */}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            :
                            <div className='txt-center' style={{ paddingTop: 40 }}>
                                <p className='fs-16' style={{ paddingTop: 10, fontWeight: 600 }}>{t('table.noRecord')}</p>
                            </div>
                    }
                </>
            </div>
            : null
            }
            { 
                open && 
                <Dialog
                    onClose={() => setOpen(null)}
                    aria-labelledby="customized-dialog-title"
                    open={open ? true : false}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {t('wallet.details')}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(null)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="w-full">
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.orderId')}</Typography>
                            <Typography style={{ fontSize: 14 }}>#{open?.id}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('general.createdAt')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{moment(open.created_at).local().format('YYYY-MM-DD')}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.rate')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{open?.details?.system_rate}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.marketRate')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>~ {info.marketRate}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.price')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{(open?.details?.system_rate * open.final_amount)}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>MKC {t('wallet.amount')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{currencyFormat(open.final_amount,4)}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.marketValue')} USDT</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>~ {currencyFormat(open.final_amount*info.marketRate, 2)}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.releasedAmount')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{currencyFormat(open?.final_amount-open?.details?.balance,4)}</Typography>
                        </div>
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.lockedAmount')}</Typography>
                            <Typography style={{ fontSize: 14, textAlign: 'right' }}>{currencyFormat(open?.details?.balance, 4)}</Typography>
                        </div>
                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                        <div className="flex-sb-m">
                            <Typography style={{ color: '#7A8AA0', fontSize: 12 }}>{t('wallet.nextRelease')} <br />({t('wallet.date')})</Typography>
                            <Typography style={{ color: '#7A8AA0', fontSize: 12, textAlign: 'right' }}>{t('wallet.amount')} <br />(~ {t('wallet.marketValue')} USDT)</Typography>
                        </div>
                        
                        {
                            displayNextRelease(open)
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setOpen(null)}>
                            {t('button.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
}));
import { makeStyles, useTheme } from '@mui/styles';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import "@css/tree.css";
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { currencyFormat } from '@utils/Tools';
import GenealogySponsor from './GenealogySponsor';
import BonusList from './BonusList';

import { WalletConnect } from '@layouts/WalletConnect';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

import { LuCopy } from "react-icons/lu";
import _ from 'lodash';


export default function Index(props) {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken } = useSelector(state => state.general);
    const { username } = useSelector(state => state.user);
    const { modal } = WalletConnect();

    const [state, setState] = useState({
        totalDownlines: 0,
        currentRank: [],
        totalWalletEarned: null,
        userPlan: [],
        commissions: []
    });
    const [tabValue, setTabValue] = useState('referral');
    const [commissions, setCommissions] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const copyShareLink = useCallback((v) => {
        if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = v;
            textarea.style.position = "fixed";
            textarea.style.width = '2em';
            textarea.style.height = '2em';
            textarea.style.padding = 0;
            textarea.style.border = 'none';
            textarea.style.outline = 'none';
            textarea.style.boxShadow = 'none';
            textarea.style.background = 'transparent';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                addAlert('', t('general.copied'), "success");
            }
            catch (e) {
                document.body.removeChild(textarea);
                addAlert('', t('general.copyFailed') + ": " + e, "warning");
            }
        } else if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
            navigator.clipboard.writeText(v).then(() => {
                addAlert('', t('general.copied'), "success");
            }).catch((error) => {
                addAlert('', t('general.copyFailed') + ": " + error, "warning");
            });
        }
        else {
            addAlert('', "Copy is not supported by browser", "error");
        }
    }, []);

    useEffect(() => {
        isMountedRef.current = true;
        if (accessToken) {
            setLoading(true);
            getUrl('/community').then(response => {
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            totalDownlines: response.data.total_downlines,
                            currentRank: response.data.current_rank,
                            totalWalletEarned: response.data.total_wallet_earned,
                            userPlan: response.data.user_plan,
                            sales: response.data.sales,
                        });
                    } else {
                        addAlert('', response.message || t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                if (isMountedRef.current) {
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                }
            }).finally(() => {
                if (isMountedRef.current) {
                    setLoading(false);
                }
            });

            getUrl('/user/commissions').then(response => {
                if (response.status && isMountedRef.current) {
                    setCommissions(Object.values(response.data.comm));
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [accessToken])

    return (
        <div className='p-t-90 p-lr-20' style={{ minHeight: '100vh' }}>
            <div className='txt-center flex-col' style={{ gap: 8 }}>
                <Typography style={{ fontSize: 36, fontWeight: 600 }}>{t('title.community')}</Typography>
                <Typography style={{ fontSize: 14, fontWeight: 400, color: theme.palette.gray.main }}>{t('genealogy.referralProgramSubtitle')}</Typography>
            </div>
            <div className="bor20 m-t-30" style={{ backgroundColor: '#27272E'}}>
                <div className="p-lr-20">
                    <Typography style={{ color: 'white', paddingTop: 20 }}>{t('genealogy.referralCode')}</Typography>
                    {
                        accessToken ?
                        <div className="flex-sb-m" style={{ backgroundColor: '#2F2F37', border: '1px solid #48484F', padding: 16, borderRadius: 10, marginTop: 20 }}>
                            <Typography style={{ color: 'white' }}>{username}</Typography>
                            <LuCopy className='pointer clwhite' onClick={() => copyShareLink(username)} />
                        </div>
                        :
                        <Button variant='contained' style={{ borderRadius: 10, marginTop: 20 }} sx={{ padding: '12px 16px' }} onClick={() => modal.open()}><p className='flex-m txt-title fs-14'>{t('general.connectWallet')}</p></Button>
                    }
                </div>
                <div className="flex-col" style={{ marginTop: 20, padding: 20, borderRadius: 20, gap: 40, backgroundColor: 'white' }}>
                    <div className="flex-row">
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.hubPlan')}</Typography>
                            <Typography className="fs-16"><b>{state?.userPlan?.plan_name?.[i18n.language] || '-'}</b></Typography>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.totalReferrals')}</Typography>
                            <Typography className="fs-16"><b>{state.totalDownlines || '-'}</b></Typography>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.directSales')}</Typography>
                            <Typography className="fs-16"><b>{state?.sales?.direct_sales ? currencyFormat(state?.sales?.direct_sales) : '-'}</b></Typography>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.groupSales')}</Typography>
                            <Typography className="fs-16"><b>{state?.sales?.group_sales ? currencyFormat(state?.sales?.group_sales) : '-'}</b></Typography>
                        </div>
                    </div>
                    <div className="flex-row">
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.totalWalletEarned', {wallet: 'BP'})}</Typography>
                            <Typography className="fs-16"><b>{state?.totalWalletEarned?.['bp'] ? '$'+currencyFormat(state.totalWalletEarned['bp']) : '-'}</b></Typography>
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography style={{ fontSize: 14 }}>{t('genealogy.totalWalletEarned', {wallet: 'RP'})}</Typography>
                            <Typography className="fs-16"><b>{state?.totalWalletEarned?.['rp'] ? '$'+currencyFormat(state.totalWalletEarned['rp']) : '-'}</b></Typography>
                        </div>
                    </div>
                </div>
            </div>

            <Tabs
                style={{ marginTop: 30 }}
                value={tabValue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="tabs list"
                >
                    <Tab label={t('genealogy.tabs.referral')} value="referral" className={tabValue === "referral" ? styles.selectedTab : styles.tab} style={{ borderRadius:  _.size(commissions)===0 ? '10px': '10px 0 0 10px' }}/>
                    {
                        _.size(commissions)>0 && _.map(commissions, _comm => (
                            <Tab key={_comm.bonus_type} label={_comm.name[i18n.language]} value={_comm.bonus_type} className={tabValue === _comm.bonus_type ? styles.selectedTab : styles.tab} style={_.last(commissions).id === _comm.id ? { borderRadius: '0 10px 10px 0', borderRightWidth: 1 } : null}/>
                        ))
                    }
            </Tabs>

            {tabValue === "referral" && <GenealogySponsor />}
            {_.size(commissions)>0 && _.map(commissions, 'bonus_type').includes(tabValue) && <BonusList commission={_.find(commissions, {bonus_type: tabValue})}/>}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    selectedTab: {
        backgroundColor: '#00A3FF', 
        color: 'white !important', 
        textTransform: 'capitalize'
    },
    tab:{
        borderWidth: '1px 0 1px 1px',
        borderColor: '#000A3D24', 
        color: '#7A8AA0', 
        borderStyle: 'solid',
        textTransform: 'capitalize'
    }

}));

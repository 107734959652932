import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { Button, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import theme from '../../Theme';
import { WalletConnect } from '@layouts/WalletConnect';
import { FiChevronLeft } from "react-icons/fi";
import { currencyFormat } from '@utils/Tools';


export default function GenealogySponsor(props) {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const { username } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const { modal } = WalletConnect();

    const [tree, setTree] = useState([]);
    const [state, setState] = useState({ totalDirectDownlines: 0, totalDownlines: 0, username: '', uplines: [] });

    useEffect(() => {
        isMountedRef.current = true;
        setState({ ...state, username: username });
        return () => isMountedRef.current = false;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (accessToken) {
            searchUsername()
        }
    }, [accessToken])

    const searchUsername = (targetUser) => {
        setLoading(true);
        getUrl(`/sponsor-genealogy`, { username: targetUser ? targetUser : username }).then(response => {
            if (isMountedRef.current) {
                let { status, message, error, data } = response;
                if (status) {
                    setState({ ...state, username: '', uplines: data.upline });
                    setTree(data.tree_data);
                } else {
                    if (error || message) {
                        if (_.size(error) > 0) {
                            _.map(error, (value, key) => {
                                message += "\n " + value[0];
                            })
                        }
                        addAlert(message);
                    }
                }
            }
            setLoading(false);
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            }
        });
    }

    const renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={`${nodes.id}`}
            className={`${_.size(nodes.children) > 0 ? '' : styles.disabledCursor}`}
            onClick={() => searchUsername(nodes.username)}
            disabled={_.size(nodes.children) > 0 ? false : true}
            id="sponsor"
            label={
                <div >
                    <div className="divider-dark m-tb-10"></div>
                    <div>
                        <div className='bor10 p-tb-10 w-full' style={{}}>
                            <div>
                                <div className='flex-sb-m w-full'>
                                    <div>
                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.walletAddress')}</p>
                                        <p className='fs-14 p-t-8 txt-truncate' style={{ maxWidth: 200, fontWeight: 500 }}>{nodes.username}</p>
                                    </div>
                                    <div>
                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.hubPlan')}</p>
                                        <p className='fs-14 p-t-8 txt-truncate' style={{ fontWeight: 500 }}>{(nodes.rank_code || nodes.crank_code) ? (nodes.rank_code > nodes.crank_code ? JSON.parse(nodes.rank_name)[i18n.resolvedLanguage || 'en'] : JSON.parse(nodes.crank_name)[i18n.resolvedLanguage || 'en']) : '-'}</p>
                                    </div>
                                    <div>
                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.downlineCount')}</p>
                                        <div className='fs-14 p-t-8 txt-truncate' style={{ color: '#00A3FF', fontWeight: 500 }}>
                                            <p>{nodes.total_direct_downline}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-m w-full m-t-10'>
                                    <div style={{ flex: 1 }}>
                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.personalSales')}</p>
                                        <p className='fs-14 p-t-8 txt-truncate' style={{ fontWeight: 500 }}>{currencyFormat(nodes.personal_sales)}</p>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <p className='fs-12' style={{ color: '#7A8AA0' }}>{t('genealogy.groupSales')}</p>
                                        <p className='fs-14 p-t-8 txt-truncate' style={{ fontWeight: 500 }}>{currencyFormat(nodes.group_sales)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        >
        </TreeItem>
    );

    return (
        <div className='m-t-20 m-b-80 p-all-20 bor20' style={{ background: '#ffffff' }}>
            <p className='fs-16 p-all-5' style={{ fontWeight: 600 }}>{t('genealogy.referralList')}</p>

            {/* Breadcrumbs */}
            {
                _.size(state.uplines) > 0 &&
                <div className='flex-m downlinelist fs-16 p-t-20' style={{ color: '#00A3FF' }}>
                    <div className='flex-col' style={{ color: '#00A3FF' }}>
                        <div className='flex-m m-lr-5 '>
                            <FiChevronLeft className='fs-16' style={{ verticalAlign: 'bottom' }} />
                            <p className='pointer' style={{ color: '#00A3FF' }} onClick={() => searchUsername()}>{t('general.back')}</p>
                        </div>
                        {
                            tree.username != username &&
                            <p className='p-t-10 p-l-10' style={{ color: '#00A3FF' }}>
                                {tree.username}
                            </p>
                        }
                    </div>
                </div>
            }
            {
                (_.size(tree?.children) > 0) ?
                    <>

                        <TreeView
                            className={styles.tree}
                            style={{ position: 'relative', zIndex: 0 }}
                        >
                            {_.map(tree.children, node => (renderTree(node)))}
                        </TreeView>
                    </>
                    :
                    <Card style={{ border: 'none', background: 'transparent' }} variant="outlined">
                        <CardContent className='txt-center' style={{ height: accessToken ? 400 : 100, borderRadius: 0 }}>
                            {
                                accessToken ?
                                    <div>
                                        <p className='txt-white fs-18 p-t-16' style={{ paddingTop: 16, fontWeight: 600 }}>{t('genealogy.noRecord')}</p>
                                        <p className='txt-white fs-14 p-t-8' style={{ color: '#D0D5DD' }}>{t('genealogy.noRecordDetails')}</p>
                                    </div>
                                    :
                                    <div>
                                        <p className='fs-12 p-tb-16' style={{ color: theme.palette.gray.main, paddingTop: 16, fontWeight: 600 }}>{t('genealogy.connectWallet')}</p>
                                        <Button variant="contained" onClick={() => modal.open() } style={{ justifyContent: "flex-start" }}>
                                            <p className='p-lr-10 fs-14'>{t('button.walletConnect')}</p>
                                        </Button>
                                    </div>
                            }
                        </CardContent>
                    </Card>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    tree: {
        flexGrow: 1,
    },
    disabledCursor: {
        cursor: 'default',
        pointerEvents: 'none'
    }
}));